import { useAtomValue, useSetAtom } from 'jotai'
import { Notification as NotificationType, notificationsAtom } from 'lib/atoms'
import { FC } from 'lib/component-utils'
import { AnimatePresence } from 'motion/react'
import Notification from 'components/base/Notification'
import { WritableDraft } from 'immer/dist/internal'
import { HTMLProps, useCallback, useState } from 'react'
import { Action } from 'react-calendar/dist/cjs/shared/types'

export type ToastType = 'success' | 'info' | 'warning' | 'error'

let id = 0

export const useToast = () => {
  const updateNotificationState = useSetAtom(notificationsAtom)

  const sendToast = useCallback(
    (
      type: ToastType,
      title: string,
      message?: string,
      options?: {
        primaryLink?: { title: string; props: HTMLProps<HTMLAnchorElement> }
        primaryButton?: Action
        secondaryButton?: Action
      }
    ) =>
      updateNotificationState((s) => {
        const currentId = id++
        s.push({
          id: currentId,
          type,
          title,
          message,
          notificationType: 'toast',
          ...(options as any),
        })
        setTimeout(() => {
          updateNotificationState((s) => {
            const notification = s.find((e) => e.id === currentId)
            if (notification && !notification.isHovered) {
              return s.filter((e) => e.id !== currentId)
            }
            return s
          })
        }, 3000)
      }),
    [updateNotificationState]
  )

  return sendToast
}

export const useNotification = () => {
  const updateNotificationState = useSetAtom(notificationsAtom)

  const sendNotification = useCallback(
    (notification: Omit<NotificationType, 'id' | 'notificationType'>) =>
      updateNotificationState((s) => {
        const currentId = id++
        s.push({
          id: currentId,
          notificationType: 'notification',
          ...notification,
        } as WritableDraft<NotificationType>)
        setTimeout(() => {
          updateNotificationState((s) => {
            const notification = s.find((e) => e.id === currentId)
            if (notification && !notification.isHovered) {
              return s.filter((e) => e.id !== currentId)
            }
            return s
          })
        }, 8000)
      }),
    [updateNotificationState]
  )

  return sendNotification
}

const NotificationArea: FC = () => {
  const notifications = useAtomValue(notificationsAtom)
  const updateNotificationState = useSetAtom(notificationsAtom)

  const handleMouseEnter = useCallback(
    (id: number) => {
      updateNotificationState((s) => {
        const notification = s.find((e) => e.id === id)
        if (notification) {
          notification.isHovered = true
        }
      })
    },
    [updateNotificationState]
  )

  const handleMouseLeave = useCallback(
    (id: number) => {
      updateNotificationState((s) => {
        const notification = s.find((e) => e.id === id)
        if (notification) {
          notification.isHovered = false
          // Check if the notification is still in the state and not hovered before starting the timeout
          setTimeout(
            () => {
              updateNotificationState((s) => {
                const currentNotification = s.find((e) => e.id === id)
                if (currentNotification && !currentNotification.isHovered) {
                  return s.filter((e) => e.id !== id)
                }
                return s
              })
            },
            notification.notificationType === 'toast' ? 3000 : 8000
          )
        }
      })
    },
    [updateNotificationState]
  )

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[9999]"
    >
      <div className="flex flex-col-reverse items-center w-full space-y-4 space-y-reverse sm:items-end">
        <AnimatePresence>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              onMouseEnter={() => handleMouseEnter(notification.id)}
              onMouseLeave={() => handleMouseLeave(notification.id)}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default NotificationArea
